import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Reservation } from '../types/reservation'

export const useRedirectToReschedule = (
  reservation: Reservation | null,
  path = 'trial_lessons'
) => {
  const router = useRouter()

  useEffect(() => {
    if (!reservation) return

    const currentParams = window.location.search
    const redirectUrl = `/${path}/${reservation.id}/reschedule${currentParams}`
    router.replace(redirectUrl)
  }, [reservation, router])
}
