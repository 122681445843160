import * as React from 'react'
import Image from 'next/image'
import useMediaQuery from '@mui/material/useMediaQuery'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './index.module.scss'

import TrialContents from '../../layouts/TrialContents'

import SatisfiedChart from '../../public/trial_lesson_content_satisfied_chart.png'

import TrialLessonBg from '../../public/triallesson_bg.png'

import PresentContentPc from '../../public/present_content_pc.png'
import PresentContentSp from '../../public/present_content_sp.png'

import OtlBg from '../../public/otl_bg.png'
import Otl1 from '../../public/otl_1.png'
import Otl2 from '../../public/otl_2.png'
import Otl3 from '../../public/otl_3.png'

import Ons1 from '../../public/ons_1.png'
import Ons2 from '../../public/ons_2.png'
import OnsBg from '../../public/ons_bg.png'

import { ContentType } from '../../types/trialLessons'
import { THRESHOLD_DESKTOP_WIDTH } from '../../utils/constants'

type Props = {
  contentType: ContentType
}

const TRIAL_LESSON_CONTENTS = {
  normal: {
    title: '無料体験レッスンの内容',
    backgroundImage: TrialLessonBg.src,
    images: [],
  },
  onlyOtl: {
    title: '人気コース体験の流れ',
    backgroundImage: OtlBg.src,
    images: [
      { src: Otl1.src, alt: '1. SHElikesについて' },
      { src: Otl2.src, alt: '2. プチお仕事体験' },
      { src: Otl3.src, alt: '3. カウンセリング' },
    ],
  },
  crm: {
    title: '人気コース体験の流れ',
    backgroundImage: OtlBg.src,
    images: [
      { src: Otl1.src, alt: '1. SHElikesについて' },
      { src: Otl2.src, alt: '2. プチお仕事体験' },
      { src: Otl3.src, alt: '3. カウンセリング' },
    ],
  },
  recommendOns: {
    title: '少人数相談会の流れ',
    backgroundImage: OnsBg.src,
    images: [
      { src: Ons1.src, alt: '1. SHElikesについて' },
      { src: Ons2.src, alt: '2. カウンセリング' },
    ],
  },
}

const PRESENT_CONTENT = {
  pc: {
    src: PresentContentPc.src,
    width: 600,
    height: 880,
  },
  sp: {
    src: PresentContentSp.src,
    width: 330,
    height: 720,
  },
}

const SLIDER_PROPS = {
  className: styles.slider,
  centerMode: false,
  centerPadding: '30px',
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
}

export const TrialLessonContent = ({ contentType }: Props) => {
  const isPc = useMediaQuery(`(min-width:${THRESHOLD_DESKTOP_WIDTH}px)`)
  const { backgroundImage, title, images } = TRIAL_LESSON_CONTENTS[contentType]
  const presentContent = isPc ? PRESENT_CONTENT['pc'] : PRESENT_CONTENT['sp']

  const scrollToReservationStepSummary = () => {
    const anchor = document.getElementById('reservation-step-summary')
    anchor?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <section
        className={styles.container}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <h2 className={styles.title}>{title}</h2>
        {contentType === 'normal' ? (
          <TrialContents />
        ) : (
          <>
            {isPc ? (
              <div className={styles.pcImageWrapper}>
                {images.map(({ src, alt }: { src: string; alt: string }) => {
                  return (
                    <div key={src} className={styles.pcImageItem}>
                      <Image width={255} height={175} src={src} alt={alt} />
                    </div>
                  )
                })}
              </div>
            ) : (
              <>
                <div className={styles.subtitle}>
                  <span>横にスライドしてご覧になれます</span>
                </div>
                <Slider {...SLIDER_PROPS} className={styles.slider}>
                  {images.map(({ src, alt }: { src: string; alt: string }) => {
                    return (
                      <div key={src}>
                        <Image
                          className={styles.sliderImage}
                          width={358}
                          height={245}
                          src={src}
                          alt={alt}
                        />
                      </div>
                    )
                  })}
                </Slider>
              </>
            )}
            <Image
              width={300}
              height={400}
              src={SatisfiedChart.src}
              alt="人気コース体験参加後98%の方が満足と回答"
            />
          </>
        )}
        <div className={styles.presentContent}>
          <Image
            width={presentContent.width}
            height={presentContent.height}
            src={presentContent.src}
            alt="無料体験レッスン予約で、大人気オンライン講座を無料プレゼント"
            onClick={scrollToReservationStepSummary}
          />
        </div>
      </section>
      <svg
        className={styles.stepArrow}
        xmlns="http://www.w3.org/2000/svg"
        width="412"
        height="37"
        viewBox="0 0 412 37"
        fill="none"
      >
        <path
          d="M205.726 37L3.23464e-06 3.26464e-05L412 3.8147e-05L205.726 37Z"
          fill="#F3F5F5"
        />
      </svg>
    </>
  )
}
