import { GetServerSidePropsContext } from 'next'
import styles from './index.module.scss'

import TrialHeaderCountdown from '../../layouts/TrialHeaderCountdown'
import TrialHeader from '../../layouts/TrialHeader'
import { LessonListContent } from '../../components/LessonListContent'
import { TrialLessonContent } from '../../components/TrialLessonContent'
import { AwardContent } from '../../components/AwardContent'

import { ContentType } from '../../types/trialLessons'
import { useCampaignAndCountdownStatus } from '../../utils/campaignCountdown'
import { CurrentUser } from '../../types/currentUser'
import { Reservation } from '../../types/reservation'
import { useRedirectToReschedule } from '../../hooks/useRedirectToReschedule'

type Props = {
  contentType: ContentType
  currentUser?: CurrentUser
  jwtToken: string
  likesReservation: Reservation | null
}

const TrialLessons = ({
  contentType,
  currentUser,
  jwtToken,
  likesReservation,
}: Props) => {
  const { isCountdownActive } = useCampaignAndCountdownStatus()

  // 有効な予約があれば、振替ページにリダイレクト
  useRedirectToReschedule(likesReservation)

  return (
    <>
      <div className={styles.container}>
        {isCountdownActive && <TrialHeaderCountdown />}
        <TrialHeader
          contentType={contentType}
          isCountdownActive={isCountdownActive}
        />
        <AwardContent contentType={contentType} />
        <TrialLessonContent contentType={contentType} />
        <section
          className={styles.applyContainer}
          id="reservation-step-summary"
        >
          <LessonListContent
            contentType={contentType}
            jwtToken={jwtToken}
            currentUser={currentUser}
          />
        </section>
      </div>
    </>
  )
}

export const getServerSideProps = async ({
  query,
}: GetServerSidePropsContext) => {
  const { utm_campaign, utm_term, type } = query
  let contentType = 'onlyOtl'
  const onsParams = [utm_campaign, utm_term, type]
  if (onsParams.some(p => p?.includes('_ons'))) {
    contentType = 'recommendOns'
  }

  return {
    props: { contentType },
  }
}

export default TrialLessons
